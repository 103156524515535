import React, { Component } from "react"
import Layout from "../../components/layout"
import Clutch from "../../components/clutch"
import Pernail_logo from "../../images/perennialcycle/logo-perennial.png"
import Perennialcycle from "../../images/perennialcycle/cycle.jpg"

import Scroll_top_img from "../../images/scroll-arrow.png"
import {Helmet} from "react-helmet"

import { Link } from "gatsby";
import Calltoaction from "../../components/Call-to-action"

export default class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: ''
        }
    }

    componentDidMount() {
        this.setState({
            url: document.location.href
        });
    }
    render() {
        return (
            <Layout>
                <Helmet>
                    <title>Case Study for Google Shopping Campaigns - PerennialCycle</title>
                    <meta name="description"
                        content="A Case Study on how CueForGood optimized PerennialCycle's Google Shopping Campaigns to increase revenue by 616%. Click to find out more." />
                    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                    <link rel="canonical" href={this.state.url} />

                    <meta property="og:site_name" content="CueForGood" />
                    <meta property="fb:app_id" content="289086684439915" />
                    <meta property="og:url" content={this.state.url} />
                    <meta property="og:title"
                        content="Case Study for Google Shopping Campaigns - PerennialCycle" />
                    <meta property="og:description"
                        content="A Case Study on how CueForGood optimized PerennialCycle's Google Shopping Campaigns to increase revenue by 616%. Click to find out more." />
                    <meta property="og:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:description"
                        content="A Case Study on how CueForGood optimized PerennialCycle's Google Shopping Campaigns to increase revenue by 616%. Click to find out more." />
                    <meta name="twitter:title"
                        content="Case Study for Google Shopping Campaigns - PerennialCycle" />
                    <meta name="twitter:site" content="@CueForGood" />
                    <meta name="twitter:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
                </Helmet>
               <div className="cb_expertiseInner">
                  <div className="case_study_page perennialcycle ">
                     <div className="case-wrap  ">
                           <div className="ww-sec-one ">
                              <div className="container">
                                 <h3>Case Study</h3>
                                 <h1><img src={Pernail_logo} alt="Perenial cycle logo" /></h1>
                             <p className="perennialcycle_p">How CueForGood helped this Minnesota based online store for bikes, and trikes optimize 
                                       Google Shopping Engine to achieve a <strong>616.91% </strong> increase in revenue.</p>

                                 <div className="revenue-perennial">
                                    <div className="revenue-inn">
                                       <div className="revenue-single circle1">
                                      <div className="revenue-single-outer">
                                      <div className="progress blue">
                                         <span className="progress-left">
                                            <span className="progress-bar"></span>
                                         </span>
                                         <span className="progress-right">
                                            <span className="progress-bar"></span>
                                         </span>
                                            <div className="progress-value"> 616.91%</div>
                                      </div>
                                   </div>

                              
                                        <p>increase in<br /> revenue</p>
                                       </div>
                                       <div className="revenue-single circle2 decrease">
                                      <div className="revenue-single-outer">
                                         <div className="progress yellow">
                                            <span className="progress-left">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right hiddenprogress">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value"> 25%</div>
                                         </div>
                                      </div>

                               
                                      <p>decrease in<br /> Cost Per Click (CPC)</p>
                                       </div> 
                                       <div className="revenue-single circle3">
                                      <div className="revenue-single-outer">
                                         <div className="progress pink">
                                            <span className="progress-left">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value"> 928.94%</div>
                                         </div>
                                      </div>
                              
                                      <p>increase in<br /> transactions</p>
                                       </div>
                                       <div className="revenue-single circle4">
                                      <div className="revenue-single-outer">
                                         <div className="progress green">
                                            <span className="progress-left">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <span className="progress-right">
                                               <span className="progress-bar"></span>
                                            </span>
                                            <div className="progress-value"> 1413.28%</div>
                                         </div>
                                      </div>
                                          <p>increase in<br /> traffic</p>
                                       </div>
                                    </div>
                                 </div>
                             <p className="note">NOTE: Comparing the results from Google Shopping Engine for the month of Jan 2020 vs June 2020.</p>
                              </div>
                           </div>

                       

                     </div>
                     <div className="cb_good_section call-action-component">
                        <Calltoaction sid="call1"/>
                     </div>
                     <div className="client-situation">
                        <div className="container">
                           <div className="upper-heading">
                             <h2 className="heading_main">Client’s Situation</h2>
                             <p>As a company that focuses on bringing bikes and trikes to people’s lives for good, Perennial Cycle, realized that running shopping ads has the potential to exhibit their products and engage new audiences. The brand had experimented with shopping engine ads in the past and felt 
                                it was in its best interest to engage an expert in the domain for help and establish a more substantial online presence.</p>
                           </div>

                           <div className="strategy-section">
                             <h2 className="heading_main">Strategy</h2>
                             <p role="heading" aria-level="4">We started by following the three ​R​ rule:</p>
                             <h3>Curated a set of ​<strong>Right Products</strong> to match the <strong>​Right Search Queries</strong><br /> at the ​<strong>Right Price ​(CPC)</strong></h3>
                           </div>
                        </div>
                     </div>

                    <div className="steps-casestudy">
                       <div className="container">
                          <div className="upper-heading">
                             <p>Here’s how we translated this rule of thumb into tactics to drive more business:</p>
                          </div>

                          <div className="steps-inner">

                           <div className="step-details">
                              <div className="step-heading">
                                   <h2 className="heading_main">Right Products</h2>
                                 <p>We studied the entire range of products and selected a few hot-selling ones that had a high average order value.</p>
                              </div>
                                <h3>Our approach</h3>
                                <ul>
                                   <li>Broke a single campaign into multiple campaigns/ad-groups according to different categories/brands.</li>
                                   <li>Increased bids for products that were fast-selling and decreased bids for the ones that were not catching everyone’s eye.</li>
                                </ul>
                                <p>As a result, the company saw an increase in traffic and a spike in Average Order Value (AOV) and overall revenue.</p>
                           </div>

                             <div className="step-details">
                                <div className="step-heading">
                                   <h2 className="heading_main">Right Search Queries</h2>
                                   <p>The beauty of shopping engines is that they allow you to choose a set of keywords for which the shopping ads
                                    should appear. While most advertisers are aware of this, they at times are not successful in implementing the
                                    strategy of picking the right search queries with efficiency.</p>
                                </div>
                                <h3>Our approach</h3>
                                <ul>
                                   <li>Conducted a search query analysis to pick the keywords that could help in optimizing the product feed.</li>
                                   <li>For keywords that were bringing in significant traffic, but not resulting in corresponding conversions were just eating
                                    away the marketing budget. We added such keywords as ‘negatives’ and hence rejected this non-converting/
                                    non-relevant traffic altogether.</li>
                                </ul>
                             </div>
                             
                             <div className="step-details">
                                <div className="step-heading">
                                   <h2 className="heading_main">Right Price</h2>
                                   <p>Our goal here was not to pay the minimum amount for each click and get cheap traffic irrespective of its relevance.
                                    Instead, we were focussing on getting highly relevant, quality traffic at the desired CPC.</p>
                                </div>
                                <h3>Our approach</h3>
                                <ul>
                                   <li>Lowered CPCs for non-profitable and low-performing products.</li>
                                   <li>Moved away from “Maximise Clicks” bid strategy and opted for Manual CPC.</li>
                                   <li>Based on campaign performance, we increased the shopping engine budgets to accommodate the growing
                                       relevant traffic.</li>
                                </ul>
                             </div>


                          </div>
                          <div className="seo-perennial-cyle-review">
                              <div className="seo-perennial-cyle-review-heading">
                                 <span className="red-line"></span>
                                 <span className="green-line"></span>
                              </div>
                              <h4>“They under-promise and over-perform. They don’t glue jewels to anything, rather they speak plainly about how much time and money something will take. Then, they do it faster and better than I expect. It happens every time, and it’s unbelievable to me.”</h4>
                              <h5>Luke Breen</h5>
                              <h6>owner</h6>
                              <div className="bg-img-quato">
                              <svg width="210" height="164" viewBox="0 0 210 164" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M8.30078 163.086V150.879C25.0651 144.694 38.2487 135.01 47.8516 121.826C57.4544 108.48 62.2559 94.6452 62.2559 80.3223C62.2559 77.2298 61.7676 74.9512 60.791 73.4863C60.3027 72.5098 59.6517 72.0215 58.8379 72.0215C58.0241 72.0215 56.722 72.6725 54.9316 73.9746C49.7233 77.7181 43.2129 79.5898 35.4004 79.5898C25.9603 79.5898 17.6595 75.8464 10.498 68.3594C3.49935 60.7096 0 51.6764 0 41.2598C0 30.1921 4.06901 20.5892 12.207 12.4512C20.3451 4.15039 30.3548 0 42.2363 0C55.7454 0 67.4642 5.61523 77.3926 16.8457C87.4837 27.9134 92.5293 42.8874 92.5293 61.7676C92.5293 83.7402 85.7747 103.434 72.2656 120.85C58.7565 138.265 37.4349 152.344 8.30078 163.086ZM125.732 163.086V150.879C142.497 144.694 155.68 135.01 165.283 121.826C174.886 108.48 179.688 94.6452 179.688 80.3223C179.688 77.2298 179.199 74.9512 178.223 73.4863C177.734 72.5098 177.083 72.0215 176.27 72.0215C175.456 72.0215 174.154 72.6725 172.363 73.9746C167.155 77.7181 160.645 79.5898 152.832 79.5898C143.392 79.5898 135.091 75.8464 127.93 68.3594C120.931 60.7096 117.432 51.6764 117.432 41.2598C117.432 30.1921 121.501 20.5892 129.639 12.4512C137.777 4.15039 147.786 0 159.668 0C173.177 0 184.896 5.61523 194.824 16.8457C204.915 27.9134 209.961 42.8874 209.961 61.7676C209.961 83.7402 203.206 103.434 189.697 120.85C176.188 138.265 154.867 152.344 125.732 163.086Z" fill="#EFE2D1"/>
                              </svg>
                              </div>
                           </div>
                           <div className="checkout-result checkout-result2">
                             <h4><Link to="/case-study/seo-perennial-cycle/" className="focus-visible">Check out the results of our SEO work for The Perennial Cycle. </Link></h4>
                          </div>
                       </div>
                    </div>

                    <div className="cb_good_section call-action-component">
                       <div className="service_weoffer cb_testimonails">
                        <Calltoaction sid="call2"/>
                        </div>
                     </div>

                    <div className="result-perennialcycle">
                       <div className="container">
                       <div className="result-block">
                             <h2 className="heading_main">Results</h2>
                           <ul>
                                <li>Shopping ads toppled Google Organic as the number one source for traffic and the most number of
                                 transactions. Also, a majority of visitors from Shopping ads consisted of new users.</li>
                                <li>The number of transactions from Google Shopping increased by 928.94% resulting in a
                                 616.91% increase in revenue from the channel.</li>
                                 <li>
                                    With a 25% decrease in Cost Per Click (CPC), we were able to increase the relevant traffic, from the
                                       channel, by 1413.28%.
                                 </li>
                           </ul>
                           <div className="img-cycle">
                                <img src={Perennialcycle} alt="perennialcycle cycle" />
                           </div>
                             <p>These results were a great encouragement for our experts assisting the client. The improvements in the KPIs were astronomical and proved the fact that with a focused optimization strategy the Google Shopping Engine channel can be a huge source of revenue generation.
                              </p>
                              <p>
  If there’s one positive take away from what has happened over the past few months due to the Covid-19 pandemic, it’s that hard work and dedication always work wonders. The revenue and business wasn’t impacted even during the uncertain times that the world was facing.
  </p>
  <p>
On that note, we continue to modify, test, and optimize our strategies for the client.</p>

                        </div>

                         
                       </div>
                    </div>

                    <div id="scroll-to" className="case-scroll">
                       <a href="#" title="Scroll To Top" className="focus-visible"><img src={Scroll_top_img} alt="Scroll To Top" /></a>
                    </div>
                  </div>
               </div>

                <Clutch />

            </Layout>
        )
    }
}